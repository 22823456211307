<template>
  <div class="search-bar">
    <form role="search" class="search-bar-wrap" @submit="onSubmit">
      <div class="search-bar-icon-wrap" aria-hidden="true">
        <SvgIcon icon="search" class="search-bar-icon" />
      </div>

      <input
        ref="searchInputElement"
        v-model="searchInput"
        aria-autocomplete="list"
        :aria-activedescendant="
          focusedResult > -1 ? `top-nav-search-item-${focusedResult}` : ''
        "
        :aria-label="inputAriaLabel"
        :aria-expanded="canShowResults"
        aria-controls="search-results"
        :class="['search-input', canShowResults && 'open']"
        autocomplete="off"
        placeholder="Search Fragrances and Supplies"
        type="text"
        @input="onInput"
        @focus="onInputFocus"
        @blur="checkBlur"
      />

      <button
        v-if="$mq.mini"
        class="submit-search"
        type="button"
        @click="onSearch"
        @blur="checkBlur"
      >
        Search
      </button>

      <div
        v-if="searchInput && searchResults.length > 0 && canShowResults"
        id="search-results"
        role="listbox"
        :class="['dropdown', canShowResults && 'open']"
      >
        <SearchBarResult
          v-for="(searchResult, resultIndex) in searchResults"
          :ref="`focusResult-${resultIndex}`"
          :key="searchResult.id"
          v-bind="searchResult"
          :focused="resultIndex === focusedResult"
          @hover="() => onResultHover(resultIndex)"
          @result-click="() => onResultClick(searchResult)"
          @blur="checkBlur"
        />

        <Button
          ref="resultsBtn"
          color="blue"
          size="medium"
          :class="[
            'query-button',
            focusedResult === searchResults.length && 'active',
          ]"
          tabindex="-1"
          type="button"
          @click="onSearch"
          @blur="checkBlur"
        >
          All results for "{{ searchInput }}"
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
  import SearchBarResult from "../SearchResult/bar.vue"
  import AlgoliaClient from "~/lib/algolia-client.js"
  import parseRawSearchResult from "~/lib/parse-raw-search-result.js"
  import debounce from "~/lib/debounce"
  import { useNotificationStore } from "~/pinia/notifications"

  const notificationStore = useNotificationStore()

  const { $mq, $csGtm } = useNuxtApp()

  const emit = defineEmits(["blur", "focus"])

  const config = useRuntimeConfig()
  const algoliaClient = new AlgoliaClient(5, {
    algoliaAppId: config.public.ALGOLIA_APPLICATION_ID,
    algoliaApiKey: config.public.ALGOLIA_API_KEY,
  })

  const searchInputElement = ref<HTMLInputElement>()
  const searchInput = ref("")
  const cachedSearchInput = ref("")
  const searchResults = ref<any[]>([])
  const canShowResults = ref(false)
  const focusedResult = ref(-1)
  const resultsBtn = ref<HTMLButtonElement | null>(null)

  const checkBlur = (e: FocusEvent) => {
    emit("blur", e)
    blurInput(e)
  }

  const route = useRoute()

  const showDropdown = (state: Boolean) => {
    if (state) {
      canShowResults.value = route.name !== "search"
    } else {
      canShowResults.value = state
    }
  }

  const onInput = async () => {
    if (searchInput.value.length > 0 && !$mq.mini.value) {
      await search()
    } else {
      showDropdown(false)

      $csGtm.trackEvent({
        event: "search-input-clear",
        query: cachedSearchInput.value,
      })
    }

    cachedSearchInput.value = searchInput.value
  }

  const onInputFocus = (e: FocusEvent) => {
    emit("focus", e)
    addArrowListeners()
    showDropdown(true)
  }

  const addArrowListeners = () => {
    window.addEventListener("keydown", onArrowKeydown)
  }

  const onArrowKeydown = (e: KeyboardEvent) => {
    if (e.key === "ArrowUp") {
      focusPreviousSearchResult()
    } else if (e.key === "ArrowDown") {
      focusNextSearchResult()
    }
  }

  const focusPreviousSearchResult = () => {
    if (focusedResult.value > -1) {
      focusedResult.value--
    }
  }

  const focusNextSearchResult = () => {
    if (focusedResult.value < searchResults.value.length) {
      focusedResult.value++
    }
  }

  const onResultHover = (index: number) => {
    focusedResult.value = index
  }

  const onResultClick = (searchResult: any) => {
    $csGtm.trackEvent({
      event: "search-result-click",
      result_url: searchResult.permalink,
      result_page_type: searchResult.pageType,
      query: searchInput.value,
    })

    blurInput()
  }

  const focus = () => {
    searchInputElement.value?.focus()
  }

  defineExpose({
    focus,
  })

  const blurInput = (e?: FocusEvent) => {
    const relatedTarget = e?.relatedTarget as HTMLElement
    if (
      relatedTarget?.classList?.contains("dropdown-result") ||
      relatedTarget?.classList?.contains("query-button")
    ) {
      return
    }

    showDropdown(false)
    focusedResult.value = -1
    searchInputElement.value?.blur()
    window.removeEventListener("keydown", onArrowKeydown)
  }

  const search = debounce(() => {
    algoliaClient
      .search({
        query: decodeURIComponent(searchInput.value),
      })
      .then((searchContent: any) => {
        showDropdown(true)

        searchResults.value = searchContent.hits.map((rawHit: any) => {
          return parseRawSearchResult({ rawHit, includePageText: true })
        })
      })
      .finally(() => {
        const message =
          searchResults.value.length > 0
            ? `${searchResults.value.length} search results found`
            : "No search results found"
        notificationStore.announce(message, {
          live: "polite",
        })
      })
  }, 300)

  const onSearch = async (e?: Event) => {
    e?.preventDefault()

    if (searchInput.value) {
      await navigateTo({
        path: `/search`,
        query: {
          q: searchInput.value,
        },
      })
      blurInput()
    }
  }

  const onSubmit = async (e: Event) => {
    e.preventDefault()

    if (
      focusedResult.value > -1 &&
      focusedResult.value < searchResults.value.length
    ) {
      const focusedItem = searchResults.value[focusedResult.value]

      if (focusedItem) {
        onResultClick(focusedItem)
        await navigateTo(focusedItem.permalink)
      }
    } else {
      onSearch()
    }
  }

  const inputAriaLabel = computed(() => {
    if (searchResults.value.length > 1) {
      return `${searchResults.value.length} search Results`
    }
    if (searchResults.value.length === 1) {
      return `${searchResults.value.length} search Result`
    }
    if (searchInput.value && searchResults.value.length === 0) {
      return `No search Result`
    }
    return "Search Fragrances and Supplies"
  })
</script>

<style lang="scss" scoped>
  .dropdown {
    background: white;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 6px);
    border-radius: $border-radius-large;
    &.open {
      box-shadow: $hover-shadow;
    }
  }

  .search-bar {
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }

  .query-button {
    width: 100%;
    @apply text-sm leading-normal;
    border-radius: $border-radius-large;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    &.active {
      background-color: $blue-700;
    }
  }

  .search-input {
    @apply text-md leading-normal;
    font-family: inherit;
    font-feature-settings: inherit;
    outline: none;
    padding-left: $base-spacing * 12;
    width: 100%;
    border-radius: 25px;
    border: 0px;
    height: $base-spacing * 12;
    background: $gray-100;
    box-sizing: border-box;
    transition:
      background-color ease-in-out 180ms,
      box-shadow ease-in-out 180ms,
      border-radius 180ms ease-in-out,
      outline 180ms ease-in-out;

    &:focus,
    &.open {
      background: #ffffff;
      box-shadow: $hover-shadow;
      border-color: transparent;
    }
    &::placeholder {
      color: $gray-text;
    }
    @include viewport("sm") {
      width: 100%;
    }
    @include viewport("mini", "sm") {
      padding: $space-s;
      padding-left: $base-spacing * 10;
      border-radius: $border-radius;
    }
  }

  .search-bar-wrap {
    position: relative;
    display: flex;
    align-items: stretch;
  }

  .submit-search {
    box-sizing: border-box;
    font-weight: bold;
    opacity: 0;
    border: none;
    background: $blue-shape;
    color: white;
    border-radius: $border-radius;
    padding: 0 $space-s;
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:focus {
      pointer-events: all;
      opacity: 1;
    }
    &.show {
      opacity: 1;
    }
  }

  .search-bar-icon-wrap {
    pointer-events: none;

    display: flex;
    align-items: center;
    color: $gray-600;
    position: absolute;
    height: 100%;
    left: $base-spacing * 4;
    width: $base-spacing * 5;

    @include viewport("mini", "sm") {
      left: $base-spacing * 3;
    }
  }
  .search-bar-icon {
    width: $base-spacing * 6;
    height: $base-spacing * 6;

    @include viewport("mini", "sm") {
      width: $base-spacing * 5;
      height: $base-spacing * 5;
    }
  }
</style>
