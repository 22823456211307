<template>
  <li
    class="mobile-nav-item"
    :aria-haspopup="columns.length > 0"
    :aria-expanded="selected"
  >
    <Item
      v-if="!rowSelected"
      :key="title"
      :title="title"
      :tag="tag"
      :image="thumbnail"
      :image-alt="thumbnailAlt"
      :title-only="columns.length == 0"
      :selected="selected"
      :href="href"
      :device="device"
      @click="selectNavItem(element.id)"
      @backButtonClick="selectNavItem(null)"
    />
    <ul
      v-show="columns.length > 0 && selected"
      class="menu"
      :aria-expanded="selected"
      :aria-hidden="!selected"
      :aria-label="`${title} submenu`"
    >
      <template v-for="column in columns" :key="column.id">
        <MobileNavRow
          v-show="!rowSelected || rowSelected === column.id"
          :element="column"
          @selected="selectRow(column.id, $event)"
        />
      </template>
    </ul>
  </li>
</template>

<script>
  import { mapActions } from "vuex"

  import Item from "./Item"
  import MobileNavRow from "./MobileNavRow"
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    components: { MobileNavRow, Item },
    mixins: [AlchemyElement],
    props: {
      selected: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      const thumbnail = this.getIngredient("thumbnail")
      return {
        title: this.getValue("title"),
        href: this.getValue("link"),
        thumbnail: thumbnail.value,
        thumbnailAlt: thumbnail.altText,
        tag: this.getValue("tag"),
        columns: this.element.nestedElements,
        rowSelected: null,
        device: this.getValue("device"),
      }
    },

    methods: {
      ...mapActions("nav", ["resetNav", "selectNavItem"]),

      selectRow(id, e) {
        e ? (this.rowSelected = id) : (this.rowSelected = e)
      },
      navToggle() {
        this.$emit("selected", !this.selected)
        this.$emit("navigated")
      },
    },
  }
</script>

<style lang="scss" scoped>
  .mobile-nav-item {
    display: block;
  }
  .menu {
    display: flex;
    padding: 0;
    flex-direction: column;
  }
</style>
