<template>
  <CsDialog
    ref="addToCartModalDialog"
    :close-on-backdrop-click="true"
    :title="store.addToCartDialog.errors ? '' : 'Added to Cart:'"
    :text="store.addToCartDialog.text"
    :cancel-label="store.addToCartDialog.errors ? 'Close' : 'Continue Shopping'"
    confirm-label="View Cart"
    @cancel="() => addToCartModalDialog?.close()"
    @confirm="goToCart"
  >
    <Alert
      v-for="error in store.addToCartDialog.errors"
      :key="error.title"
      :title="error.title"
      type="error"
      text-block
    >
      {{ error.detail }}
    </Alert>

    <template #aria-live>
      Your Shopping cart now contains the following Items:
      <span v-for="item in store.addToCartDialog.items" :key="item.id">
        {{ item.quantityAdded }} {{ item.variant.name }}.
      </span>
      Your current Cart value is: {{ store.addToCartDialog.displaySubtotal }}
    </template>

    <Alert
      v-for="(warning, key) in filteredWarnings"
      :key="key"
      type="warning"
      align="left"
      :title="warning.title || ''"
    >
      <template #default>
        {{ warning }}
      </template>
      <template v-if="warning.action" #action>
        <a :href="warning.action.href">{{ warning.action.copy }}</a>
      </template>
    </Alert>

    <div class="cart-content">
      <CartItemInfo
        v-for="item in store.addToCartDialog.items"
        :key="item.id"
        :item="item"
      />
    </div>

    <div v-if="store.addToCartDialog.items" class="cart-modal-summary">
      <span class="cart-modal-summary__label">
        Order Subtotal ({{ store.addToCartDialog.totalItemCount }}
        {{
          store.addToCartDialog.totalItemCount === 1 ? "item" : "items"
        }}):</span
      >
      <b>{{ store.addToCartDialog.displaySubtotal }}</b>
    </div>
  </CsDialog>
</template>

<script setup lang="ts">
  import CsDialog from "../CsDialog.vue"
  import CartItemInfo from "./CartItemInfo.vue"

  import { useCartStore } from "~/pinia/cart"
  import { trackAddToCart } from "~/lib/gtm/gtmTrackCart"

  const store = useCartStore()
  const router = useRouter()

  const addToCartModalDialog = ref<InstanceType<typeof CsDialog> | null>(null)

  const filteredWarnings = computed(() => {
    const { warnings } = store.addToCartDialog
    if (warnings) {
      return warnings.reduce((acc: string[], warning: string) => {
        if (typeof warning === "string" && !acc.includes(warning)) {
          acc.push(warning)
        }
        return acc
      }, [])
    }
  })

  const goToCart = () => {
    addToCartModalDialog.value?.close()
    router.push("/cart/")
  }

  const gtm = useGtm()

  store.$onAction(({ after, store, name }) => {
    switch (name) {
      case "addToCart":
        after(() => {
          addToCartModalDialog.value?.openModal()

          if (store.order) {
            trackAddToCart(gtm, store.order.lineItems)
          }
        })
        break
    }
  }, true)
</script>

<style lang="scss" scoped>
  .cart-content {
    display: flex;
    flex-direction: column;
    .cart-modal-summary {
      padding-left: $base-spacing * 2;

      @include viewport("mini") {
        padding-left: 0;
      }
    }
  }

  .cart-modal-summary {
    padding: $space-s 0;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
  }

  .cart-modal-summary__label {
    color: $gray-text;
    margin-right: $base-spacing;
  }

  .cart-modal-summary__amount {
    margin-left: $base-spacing;
    color: $gray-text;
  }
</style>
