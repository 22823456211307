<template>
  <div>
    <ClientOnly>
      <div
        class="notices relative"
        @mouseenter="pauseAnimation"
        @mouseleave="playAnimation"
      >
        <template v-for="(notice, index) in noticeAreas" :key="notice.id">
          <div
            :class="[
              noticeTypeClasses(notice.type),
              scrollPosition === index ? 'notice-active' : '',
            ]"
            class="text-center leading-5 py-3 px-10 notice"
          >
            <div class="notice-message">
              <span
                v-if="notice.title"
                class="notice-message-text font-semibold"
              >
                {{ notice.title }}
              </span>
              <span class="notice-message-text" v-if="notice.text">
                {{ notice.text }}
              </span>

              <template v-if="notice.type === 'one_oz_sale'">
                <SaleCountdown class="notice-message-text" />
              </template>
              <nuxt-link
                v-if="notice.link && notice.link.linkTitle"
                :to="notice.link.value"
                class="underline text-current font-semibold notice-message-text whitespace-nowrap"
              >
                {{ notice.link.linkTitle }}
              </nuxt-link>
              <button
                v-if="notice.details"
                class="text-sm p-0 m-0 font-sans border-none underline text-current font-semibold notice-message-text bg-transparent whitespace-nowrap"
                @click="openDetails"
              >
                <span v-if="notice.details_label">{{
                  notice.details_label
                }}</span>
                <span v-else>Details</span>
              </button>
            </div>
          </div>
        </template>
        <div class="absolute top-0 z-2 right-0">
          <ProgressSpinner
            v-show="noticeAreas.length > 1"
            ref="progressSpinnerElement"
            :class="spinnerClasses(activeNotice?.type)"
            :duration="delay"
          />
        </div>
      </div>
      <CsModal
        v-if="activeNotice"
        ref="termsModal"
        :close-on-backdrop-click="true"
        @close="onCloseDetails"
      >
        <div class="relative flex justify-between p-6 pl-8">
          <span class="text-lg font-semibold flex place-items-center">
            {{ activeNotice.title }}
          </span>

          <button
            aria-label="Close"
            class="bg-gray-100 border-none w-11 h-11 rounded-full p-2.5"
            @click="termsModal?.close()"
          >
            <SvgIcon icon="x" class="w-6 h-6 block" />
          </button>
        </div>
        <div class="p-8 pt-0">
          <RichText
            v-if="activeNotice.details"
            :content="activeNotice.details"
          />
        </div>
      </CsModal>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
  import { deserializePages } from "@alchemy_cms/json_api"
  import CsModal from "@/components/CsModal.vue"
  import ProgressSpinner from "@/components/ProgressSpinner.vue"

  const { handleError } = useErrorHandling()
  const { api } = useApi()
  const { getValue, getIngredient, getPageByUrlname } = useAlchemy()
  const saleStore = useSaleStore()

  const progressSpinnerElement = ref<InstanceType<
    typeof ProgressSpinner
  > | null>(null)

  const termsModal = ref<InstanceType<typeof CsModal> | null>(null)

  type NoticeType = "info" | "sale" | "holiday" | "alarm" | "one_oz_sale"

  const modalOpen = ref(false)
  const scrollPosition = ref(0)
  const delay = 10000

  const startTime: Ref<number | null> = ref(null)
  const stopTime: Ref<number | null> = ref(null)
  const remainingTime: Ref<number | null> = ref(null)

  const activeNotice = computed(() => {
    return noticeAreas.value[scrollPosition.value]
  })

  const nextNoticeInterval: Ref<NodeJS.Timeout | null> = ref(null)
  const nextNoticeTimeout: Ref<NodeJS.Timeout | null> = ref(null)

  const nextSlide = () => {
    startTime.value = performance.now()
    scrollPosition.value === noticeAreas.value.length - 1
      ? (scrollPosition.value = 0)
      : (scrollPosition.value += 1)
  }

  const openDetails = () => {
    modalOpen.value = true
    pauseAnimation()
    termsModal.value?.openModal()
  }

  const onCloseDetails = () => {
    modalOpen.value = false
    termsModal.value?.close()
    playAnimation()
  }

  const createInterval = () =>
    setInterval(() => {
      nextSlide()
    }, delay)

  const createTimeout = (time: number) =>
    setTimeout(() => {
      nextNoticeInterval.value = createInterval()
      nextSlide()
    }, time)

  onMounted(async () => {
    await getNoticeElements()
  })

  const pauseAnimation = () => {
    if (nextNoticeInterval.value && startTime.value) {
      // Stop animation, clear interval
      clearInterval(nextNoticeInterval.value)
      nextNoticeInterval.value = null

      stopTime.value = performance.now()
      remainingTime.value = delay - (stopTime.value - startTime.value)
    } else if (
      nextNoticeTimeout.value &&
      startTime.value &&
      remainingTime.value
    ) {
      // We remove the active timeout
      clearTimeout(nextNoticeTimeout.value)
      nextNoticeTimeout.value = null

      // We set the time to next transition to the remaining time
      stopTime.value = performance.now()
      remainingTime.value =
        remainingTime.value - (stopTime.value - startTime.value)
    }
    progressSpinnerElement.value?.pauseAnimation()
  }

  const playAnimation = () => {
    if (!modalOpen.value) {
      startTime.value = performance.now()
      if (remainingTime.value) {
        nextNoticeTimeout.value = createTimeout(remainingTime.value)
      } else {
        nextNoticeInterval.value = createInterval()
      }
      progressSpinnerElement.value?.playAnimation()
    }
  }

  interface SiteWideNoticeEntry {
    id: string
    title?: string
    text?: string
    type?: NoticeType
    details?: string
    details_label?: string
    link?: {
      value?: string
      linkTitle?: string
    }
  }

  const AlchemyNoticesToSimpleJson = (
    elements: AlchemyElement[],
  ): SiteWideNoticeEntry[] => {
    return elements.map((element) => {
      const link = getIngredient(element, "link")
      return {
        id: element.id,
        title: getValue(element, "title"),
        type: getValue(element, "type"),
        text: getValue(element, "text"),
        details: getValue(element, "details"),
        details_label: getValue(element, "details_label"),
        link: {
          value: link?.value,
          linkTitle: link?.linkTitle,
        },
      }
    })
  }

  const simpleJsonNoticeAreas = ref<SiteWideNoticeEntry[]>([])

  const noticeAreas = computed(() => {
    if (saleStore.activeSale) {
      return simpleJsonNoticeAreas.value
    } else {
      return simpleJsonNoticeAreas.value.filter(
        (notice) => notice.type !== "one_oz_sale",
      )
    }
  })

  const getNoticeElements = async () => {
    const getParams = new URLSearchParams({
      include: "all_elements.ingredients",
      "filter[page_layout_eq]": "sitewide_notice",
    })

    try {
      const data = await api(`/jsonapi/layout_pages.json?${getParams}`)
      const pages: AlchemyPage[] = deserializePages(data)
      const siteWideNotice = getPageByUrlname("sitewide-notice", pages)
      const siteWideNoticeEntries = siteWideNotice?.elements.filter(
        (element) => element.name === "sitewide_notice_entry",
      )

      simpleJsonNoticeAreas.value = AlchemyNoticesToSimpleJson(
        siteWideNoticeEntries || [],
      )

      if (simpleJsonNoticeAreas.value.length > 1) {
        nextNoticeInterval.value = createInterval()
        startTime.value = performance.now()
        progressSpinnerElement.value?.playAnimation()
      }
      return data
    } catch (error) {
      handleError(error)
    }
  }

  const noticeTypeClasses = (type: NoticeType | undefined = undefined) => {
    switch (type) {
      case "info":
        return "bg-blue-tint text-blue-text"
      case "sale":
        return "bg-green-tint text-green-text"
      case "holiday":
        return "bg-purple-tint text-purple-text"
      case "alarm":
        return "bg-red-tint text-red-text"
      case "one_oz_sale":
        return "bg-[#ffe0d5] text-onyx-500"
      default:
        return "bg-blue-tint text-blue-text"
    }
  }

  const spinnerClasses = (type: NoticeType | undefined) => {
    switch (type) {
      case "info":
        return "text-blue-text"
      case "sale":
        return "text-green-text"
      case "holiday":
        return "text-purple-text"
      case "alarm":
        return "text-red-text"
      case "one_oz_sale":
        return "text-onyx-500"
      default:
        return "text-blue-text"
    }
  }
</script>

<style scoped>
  .notices {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  .notice {
    grid-column: 1;
    grid-row: 1;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s;
    display: flex;

    &-active {
      opacity: 1;
      pointer-events: auto;
    }
    &-message {
      align-self: center;
      justify-self: center;
      width: 100%;
      &-text {
        &:not(:first-child) {
          margin-left: 3px;
        }
        &:not(:last-child) {
          margin-right: 3px;
        }
      }
    }
  }
</style>
