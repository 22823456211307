<template>
  <nuxt-link v-if="userRewardsEnabled" to="/rewards/" :class="CLASS_LINK">
    Rewards
    <CountBadge
      v-if="userDetail?.pointsAvailable > 0"
      type="rewards"
      :total="userDetail?.pointsAvailable"
    />
  </nuxt-link>
  <a href="https://support.candlescience.com" :class="CLASS_LINK"> Help </a>
  <template v-if="!loggedIn">
    <nuxt-link to="/signup/" :class="CLASS_LINK"> Register </nuxt-link>
    <nuxt-link to="/login/" :class="CLASS_LINK"> Login </nuxt-link>
  </template>
  <template v-else>
    <ClientOnly>
      <nuxt-link :class="CLASS_LINK" to="/account/orders/"> Account </nuxt-link>
    </ClientOnly>
  </template>
  <CartIconWithItemCount :item-count="itemCount" />

  <nuxt-link
    :to="loggedIn ? '/account/orders/' : '/login/'"
    aria-label="Login into your User Account"
    :class="[CLASS_BUTTON, '-order-1 mini:order-auto']"
  >
    <SvgIcon icon="account" :class="CLASS_ICON" />
  </nuxt-link>
  <button
    :class="[CLASS_BUTTON, '-order-2 mini:order-auto']"
    aria-label="Menu"
    @click="updateNavigationState(!mobileNavIsOpen)"
  >
    <template v-if="!mobileNavIsOpen">
      <SvgIcon v-show="!mobileNavIsOpen" icon="menu" :class="CLASS_ICON" />
      <CountBadge
        v-if="userRewardsEnabled && loggedIn && userDetail?.pointsAvailable > 0"
        type="rewards"
        :flash="true"
        :total="userDetail?.pointsAvailable"
      />
    </template>
    <template v-else>
      <SvgIcon v-show="mobileNavIsOpen" icon="x" :class="CLASS_ICON" />
    </template>
  </button>
</template>

<script>
  import { mapState, mapActions } from "vuex"
  import { mapState as pmapState } from "pinia"
  import { useCartStore } from "~/pinia/cart"
  import { useAuthStore } from "~/pinia/auth"

  import CartIconWithItemCount from "~/components/CartIconWithItemCount"

  export default {
    components: {
      CartIconWithItemCount,
    },

    setup() {
      const { logout } = useLogout()
      const authStore = useAuthStore()

      const CLASS_LINK =
        "hidden sm:block text-md px-3 leading-[44px] font-bold text-onyx-500 hover:text-blue-text relative"
      const CLASS_BUTTON =
        "block sm:hidden p-[10px] text-onyx-500 bg-transparent hover:text-blue-shape border-0 relative"
      const CLASS_ICON = "w-6 h-6 block"

      const userRewardsEnabled = computed(
        () => authStore.user?.featureFlags?.userRewards === true,
      )

      return {
        CLASS_LINK,
        CLASS_BUTTON,
        CLASS_ICON,
        logout,
        userRewardsEnabled,
      }
    },

    computed: {
      ...mapState("nav", {
        mobileNavIsOpen: (state) => state.mobileNavIsOpen,
      }),
      ...pmapState(useCartStore, ["itemCount"]),
      ...pmapState(useAuthStore, ["loggedIn"]),
      ...pmapState(useAuthStore, ["user"]),
      ...pmapState(useRewardStore, ["userDetail"]),
    },

    watch: {
      $route() {
        const mobileRouteUpdate = () => {
          this.$store.commit("nav/setNavigationState", false)
        }
        this.mobileNavIsOpen && mobileRouteUpdate()
      },
    },
    mounted() {
      useCartStore().loadCart()
    },
    methods: {
      ...mapActions("nav", ["updateNavigationState"]),
    },
  }
</script>
